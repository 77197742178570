<template>
    <div class="add-info">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        :colon="false"
      >
        <CardHeader
          :icon="icon1"
          title="基本信息"
          :fold="foldList.indexOf(0) > -1"
          @fold="onFold(0)"
        />
  
        <a-row v-show="foldList.indexOf(0) === -1">
     
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="姓名">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="员工类别">
              <a-select
                v-decorator="[
                  'type',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in employeeType"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="员工状态">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in employeeStatus"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="性别">
              <a-radio-group
                v-decorator="[
                  'sex',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-radio
                  v-for="item in sex"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="出生日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'birthday',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="年龄">
              <a-button block style="text-align: left">{{
                getDateLength("birthday")
              }}</a-button>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="国籍">
              <a-input
                v-decorator="[
                  'nationality',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="籍贯">
              <a-input
                v-decorator="[
                  'nativePlace',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="民族">
              <a-input
                v-decorator="[
                  'ethnicGroup',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="政治面貌">
              <a-select
                v-decorator="[
                  'political',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in politicalOutlookList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="入党(团)日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'politicalDate',
       
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="党员">
              <a-select
                v-decorator="[
                  'partyMember',
           
                ]"
              >
                <a-select-option
                  v-for="item in partyList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="血型">
              <a-select
                v-decorator="[
                  'bloodType',
            
                ]"
              >
                <a-select-option
                  v-for="item in bloodTypeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="身高(cm)">
              <a-input
                v-decorator="[
                  'height',
             
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="体重(kg)">
              <a-input
                v-decorator="[
                  'weight',
          
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="兴趣爱好">
              <a-input
                v-decorator="[
                  'interests',
          
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="专业特长">
              <a-input
                v-decorator="[
                  'expertises',
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="婚姻状况">
              <a-select
                v-decorator="[
                  'marryType',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in marrigeStatus"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="子女情况">
              <a-select
                v-decorator="[
                  'childrenType',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in childStatus"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="参加工作日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'workStartTime',
                  {
  
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="社会工龄">
              <a-button block style="text-align: left">
                {{ getDateLength("workStartTime") }}
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="社会职务">
              <a-input
                v-decorator="[
                  'socialPost',
           
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="入职日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'entryDate',
                  {
  
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="司龄">
              <a-button block style="text-align: left">
                {{ getDateLength("entryDate") }}
              </a-button>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="残疾标识">
              <a-select
                v-decorator="[
                  'deformityFlag',
               
                ]"
              >
                <a-select-option
                  v-for="item in disabilitySign"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="登录账号">
              <a-input
                v-decorator="[
                  'code',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
  

        <CardHeader
          :icon="icon3"
          title="岗位信息"
          :fold="foldList.indexOf(2) > -1"
          @fold="onFold(2)"
        />
        <a-row v-show="foldList.indexOf(2) === -1">
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="归属部门">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'belongDept',
                  {
        
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="岗位序列">
              <a-input
                v-decorator="[
                  'postSeries',
                  {
              
  
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="岗位">
              <a-select :allowClear="true" v-decorator="[
              'position',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],  
              },
            ]">
              <a-select-option v-for="item in positionList" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="直接上级姓名">
              <a-input
                v-decorator="[
                  'directSupervisor',
           
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="职级">
              <a-input
                v-decorator="[
                  'rank',
            
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="职等">
              <a-input
                v-decorator="[
                  'grade',
            
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="当前职称开始日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'rankStartTime',
             
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="任职开始日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'postStartTime',
            
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="任职结束日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'postEndTime',
              
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
  
        <CardHeader
          :icon="icon4"
          title="联系信息"
          :fold="foldList.indexOf(3) > -1"
          @fold="onFold(3)"
        />
  
        <a-row v-show="foldList.indexOf(3) === -1">
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="手机号">
              <a-input
                v-decorator="[
                  'mobile',
                  {
  
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="办公电话">
              <a-input
                v-decorator="[
                  'phone',
           
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="个人邮箱">
              <a-input
                v-decorator="[
                  'emailIndividual',
          
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="公司邮箱">
              <a-input
                v-decorator="[
                  'email',
        
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="通讯地址">
              <a-input
                v-decorator="[
                  'address',
  
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="办公地址">
              <a-input
                v-decorator="[
                  'addressWork',
           
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
  

  
        <CardHeader
          :icon="icon11"
          title="试用期信息"
          :fold="foldList.indexOf(10) > -1"
          @fold="onFold(10)"
        />
  
        <a-row v-show="foldList.indexOf(10) === -1">
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="试用期限(月)">
              <a-input-number
                v-decorator="[
                  'term',
          
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="开始日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'startTime',
          
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="计划结束日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'endTimeExpected',
              
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="实际结束日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'endTimeActual',
          
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="转正日期">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="[
                  'qualifiedTime',
                ]"
              />
            </a-form-item>
          </a-col>
  
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="考核结果"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 21 }"
            >
              <a-textarea
                v-decorator="[
                  'result',
       
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
  
        <div class="center">
          <a-space>
            <a-button :loading="saveLoading" htmlType="submit" type="primary"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </template>
  
  <script>
  import organization from "@/mixins/organization";
  import CardHeader from "./card-header.vue";
  
  import { add } from "@/api/human-resources/employee";
  import { mapGetters } from "vuex";
  
  export default {
    mixins: [organization],
  
    components: {
      CardHeader,
    },
    data() {
      return {
        form: this.$form.createForm(this),
  
        icon1: require("@/assets/employee/icon1.png"),
        icon2: require("@/assets/employee/icon2.png"),
        icon3: require("@/assets/employee/icon3.png"),
        icon4: require("@/assets/employee/icon4.png"),
        icon5: require("@/assets/employee/icon5.png"),
        icon6: require("@/assets/employee/icon6.png"),
        icon7: require("@/assets/employee/icon7.png"),
        icon8: require("@/assets/employee/icon8.png"),
        icon9: require("@/assets/employee/icon9.png"),
        icon10: require("@/assets/employee/icon10.png"),
        icon11: require("@/assets/employee/icon11.png"),
        icon12: require("@/assets/employee/icon12.png"),
        icon13: require("@/assets/employee/icon13.png"),
        icon14: require("@/assets/employee/icon14.png"),
        icon15: require("@/assets/employee/icon15.png"),
  
        foldList: [],
  
        saveLoading: false,
      };
    },
  
    computed: {
      ...mapGetters("setting", ["findDataDict"]),
  
      lists() {
        return this.findDataDict("talentMap");
      },
      positionList() {
        return (
          this.lists.find((item) => item.value === "talentMap_post").children || []
        );
      },
      // 员工类别
      employeeType() {
        return (
          this.lists.find((item) => item.value === "employeeType").children || []
        );
      },
      // 婚姻状况
      marrigeStatus() {
        return (
          this.lists.find((item) => item.value === "marrigeStatus").children || []
        );
      },
      // 子女状况
      childStatus() {
        return (
          this.lists.find((item) => item.value === "childStatus").children || []
        );
      },
      // 残疾标识
      disabilitySign() {
        return (
          this.lists.find((item) => item.value === "disabilitySign").children ||
          []
        );
      },
      // 证件类型
      credentialType() {
        return (
          this.lists.find((item) => item.value === "credentialType").children ||
          []
        );
      },
      // 人员类型
      personType() {
        return (
          this.lists.find((item) => item.value === "personType").children || []
        );
      },
      // 关系类型
      relationshipType() {
        return (
          this.lists.find((item) => item.value === "relationshipType").children ||
          []
        );
      },
  
      sex() {
        return this.findDataDict("sex");
      },
      // 政治面貌
      politicalOutlookList() {
        return this.findDataDict("politicalOutlook");
      },
  
      // 血型
      bloodTypeList() {
        return this.findDataDict("bloodType");
      },
      partyList() {
        return this.findDataDict("partyMemberType");
      },
      employeeStatus() {
        return this.findDataDict("employeeStatus");
      },
    },
  
    methods: {
      onFold(value) {
        const index = this.foldList.indexOf(value);
        if (index > -1) {
          this.foldList.splice(index, 1);
        } else {
          this.foldList.push(value);
        }
      },
      allClose() {
        this.foldList.push(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14);
      },
      getDateLength(key) {
        const str = this.form.getFieldValue(key)
        if (str && str.format) {
          const year = new Date().getFullYear();
          return year - parseInt(str.format("YYYY"));
        } else {
          return "--";
        }
      },
  
      handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log(values);
            this.saveLoading = true;
            add({
              ...values,
  
              birthday: values.birthday.format("YYYY-MM-DD"), // 出生日期
              workStartTime: values.workStartTime.format("YYYY-MM-DD"), // 参加工作日期
              entryDate: values.entryDate.format("YYYY-MM-DD"), // 入职日期
              politicalDate: values.politicalDate
                ? values.politicalDate.format("YYYY-MM-DD")
                : null,
  
              // 岗位信息
              position: {
                belongDept: values.belongDept,
                directSupervisor: values.directSupervisor,
                postSeries: values.postSeries,
                position: values.position,
                grade: values.grade,
                rank: values.rank,
                rankStartTime: values.rankStartTime
                  ? values.rankStartTime.format("YYYY-MM-DD")
                  : undefined,
                postStartTime: values.postStartTime
                  ? values.postStartTime.format("YYYY-MM-DD")
                  : undefined,
                postEndTime: values.postEndTime
                  ? values.postEndTime.format("YYYY-MM-DD")
                  : undefined,
              },
  
              belongDept: undefined,
              directSupervisor: undefined,
              postSeries: undefined,
              grade: undefined,
              rank: undefined,
              rankStartTime: undefined,
              postStartTime: undefined,
              postEndTime: undefined,
  
   
  
  
  
  
 
  
              // 实习
              probation: {
                term: values.term,
                result: values.result,
                startTime: values.startTime
                  ? values.startTime.format("YYYY-MM-DD")
                  : null,
                qualifiedTime: values.qualifiedTime
                  ? values.qualifiedTime.format("YYYY-MM-DD")
                  : null,
                endTimeExpected: values.endTimeExpected
                  ? values.endTimeExpected.format("YYYY-MM-DD")
                  : null,
                endTimeActual: values.endTimeActual
                  ? values.endTimeActual.format("YYYY-MM-DD")
                  : null,
              },
              term: undefined,
              result: undefined,
              startTime: undefined,
              qualifiedTime: undefined,
              endTimeExpected: undefined,
              endTimeActual: undefined,
  
       

  
            })
              .then(() => {})
              .finally(() => {
                this.saveLoading = false;
              });
          }
        });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .add-info {
    position: relative;
    .tool {
      position: absolute;
      top: -32px;
      right: 0;
    }
  }
  .center {
    margin-top: 80px;
    padding-bottom: 80px;
  }
  </style>