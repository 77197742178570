var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-info"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('CardHeader',{attrs:{"icon":_vm.icon1,"title":"基本信息","fold":_vm.foldList.indexOf(0) > -1},on:{"fold":function($event){return _vm.onFold(0)}}}),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.foldList.indexOf(0) === -1),expression:"foldList.indexOf(0) === -1"}]},[_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"员工类别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'type',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'type',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.employeeType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"员工状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'status',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'status',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.employeeStatus),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sex',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'sex',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.sex),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"出生日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'birthday',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'birthday',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"年龄"}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[_vm._v(_vm._s(_vm.getDateLength("birthday")))])],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"国籍"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nationality',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'nationality',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"籍贯"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nativePlace',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'nativePlace',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"民族"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'ethnicGroup',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'ethnicGroup',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"政治面貌"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'political',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'political',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.politicalOutlookList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"入党(团)日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'politicalDate',
   
            ]),expression:"[\n              'politicalDate',\n   \n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"党员"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'partyMember',
       
            ]),expression:"[\n              'partyMember',\n       \n            ]"}]},_vm._l((_vm.partyList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"血型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'bloodType',
        
            ]),expression:"[\n              'bloodType',\n        \n            ]"}]},_vm._l((_vm.bloodTypeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"身高(cm)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'height',
         
            ]),expression:"[\n              'height',\n         \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"体重(kg)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'weight',
      
            ]),expression:"[\n              'weight',\n      \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"兴趣爱好"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'interests',
      
            ]),expression:"[\n              'interests',\n      \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"专业特长"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'expertises',
            ]),expression:"[\n              'expertises',\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"婚姻状况"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'marryType',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'marryType',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.marrigeStatus),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"子女情况"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'childrenType',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'childrenType',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.childStatus),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"参加工作日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'workStartTime',
              {

                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'workStartTime',\n              {\n\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"社会工龄"}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[_vm._v(" "+_vm._s(_vm.getDateLength("workStartTime"))+" ")])],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"社会职务"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'socialPost',
       
            ]),expression:"[\n              'socialPost',\n       \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"入职日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'entryDate',
              {

                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'entryDate',\n              {\n\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"司龄"}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[_vm._v(" "+_vm._s(_vm.getDateLength("entryDate"))+" ")])],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"残疾标识"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'deformityFlag',
           
            ]),expression:"[\n              'deformityFlag',\n           \n            ]"}]},_vm._l((_vm.disabilitySign),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"登录账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'code',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1)],1),_c('CardHeader',{attrs:{"icon":_vm.icon3,"title":"岗位信息","fold":_vm.foldList.indexOf(2) > -1},on:{"fold":function($event){return _vm.onFold(2)}}}),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.foldList.indexOf(2) === -1),expression:"foldList.indexOf(2) === -1"}]},[_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"归属部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'belongDept',
              {
    
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]),expression:"[\n              'belongDept',\n              {\n    \n                rules: [\n                  {\n                    required: true,\n                    message: '请选择！',\n                  },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","filter-option":_vm.$selectFilterOption}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"岗位序列"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'postSeries',
              {
          

                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'postSeries',\n              {\n          \n\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"岗位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'position',
          {
            rules: [
              {
                required: true,
                message: '请选择！',
              },
            ],  
          },
        ]),expression:"[\n          'position',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请选择！',\n              },\n            ],  \n          },\n        ]"}],attrs:{"allowClear":true}},_vm._l((_vm.positionList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"直接上级姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'directSupervisor',
       
            ]),expression:"[\n              'directSupervisor',\n       \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"职级"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'rank',
        
            ]),expression:"[\n              'rank',\n        \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"职等"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'grade',
        
            ]),expression:"[\n              'grade',\n        \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"当前职称开始日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'rankStartTime',
         
            ]),expression:"[\n              'rankStartTime',\n         \n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"任职开始日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'postStartTime',
        
            ]),expression:"[\n              'postStartTime',\n        \n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"任职结束日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'postEndTime',
          
            ]),expression:"[\n              'postEndTime',\n          \n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1)],1),_c('CardHeader',{attrs:{"icon":_vm.icon4,"title":"联系信息","fold":_vm.foldList.indexOf(3) > -1},on:{"fold":function($event){return _vm.onFold(3)}}}),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.foldList.indexOf(3) === -1),expression:"foldList.indexOf(3) === -1"}]},[_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              {

                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'mobile',\n              {\n\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"办公电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
       
            ]),expression:"[\n              'phone',\n       \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"个人邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'emailIndividual',
      
            ]),expression:"[\n              'emailIndividual',\n      \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"公司邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
    
            ]),expression:"[\n              'email',\n    \n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"通讯地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address',

            ]),expression:"[\n              'address',\n\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"办公地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'addressWork',
       
            ]),expression:"[\n              'addressWork',\n       \n            ]"}]})],1)],1)],1),_c('CardHeader',{attrs:{"icon":_vm.icon11,"title":"试用期信息","fold":_vm.foldList.indexOf(10) > -1},on:{"fold":function($event){return _vm.onFold(10)}}}),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.foldList.indexOf(10) === -1),expression:"foldList.indexOf(10) === -1"}]},[_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"试用期限(月)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'term',
      
            ]),expression:"[\n              'term',\n      \n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"开始日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'startTime',
      
            ]),expression:"[\n              'startTime',\n      \n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"计划结束日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'endTimeExpected',
          
            ]),expression:"[\n              'endTimeExpected',\n          \n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"实际结束日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'endTimeActual',
      
            ]),expression:"[\n              'endTimeActual',\n      \n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"转正日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'qualifiedTime',
            ]),expression:"[\n              'qualifiedTime',\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"考核结果","label-col":{ span: 2 },"wrapper-col":{ span: 21 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'result',
   
            ]),expression:"[\n              'result',\n   \n            ]"}]})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"loading":_vm.saveLoading,"htmlType":"submit","type":"primary"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }