import request from '../request'
import download from '../download'

export function fetchList(data) {
    return request({
        url: '/user-service/talentMap/list',
        method: 'post',
        data
    })
}

export function downloadFile(data) {
    return download({
        url: '/user-service/talentMap/export',
        method: 'post',
        data,
        headers: {
            'content-type': 'application/json'
        }
    })
}


export function fetchDetail({ id }) {
    return request({
        url: '/user-service/talentMap/query/' + id,
    })
}

export function edit(data) {
    return request({
        url: '/user-service/talentMap/modify',
        method: 'post',
        data,
    })
}

export function editProject(data) {
    return request({
        url: '/user-service/talentMap/modifyWorkProject',
        method: 'post',
        data,
    })
}


export function add(data) {
    return request({
        url: '/user-service/talentMap/save',
        method: 'post',
        data,
    })
}

export function upload(data) {
    return request({
        url: '/user-service/talentMap/importEmployeeExcel',
        method: 'post',
        data,
    })
}

export function editStatus(data) {
    return request({
        url: '/user-service/employee/status/update',
        method: 'post',
        data,
    })
}

export function importChangeList(data) {
    return request({
        url: '/user-service/talentMap/importChangeList',
        method: 'post',
        data,
    })
}

export function importContractList(data) {
    return request({
        url: '/user-service/talentMap/importContractList',
        method: 'post',
        data,
    })
}

export function importPositionHisList(data) {
    return request({
        url: '/user-service/talentMap/importPositionHisList',
        method: 'post',
        data,
    })
}