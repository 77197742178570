<template>
  <div>
    <Pane />

    <a-card class="container">
      <AddInfo />
    </a-card>
  </div>
</template>

<script>
import AddInfo from "./components/add-info.vue";

export default {
  name: "addEmployee",

  components: {
    AddInfo,
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 0 16px;
}
</style>
