<template>
  <div class="left info-header">
    <img class="icon" :src="icon" alt="" />
    <span class="title">{{ title }}</span>
    <span class="fold" v-if="showFold" @click="$emit('fold')">
      <template v-if="!fold">
        收起
        <a-icon type="up" />
      </template>
      <template v-else>
        展开
        <a-icon type="down" />
      </template>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    fold: {
      type: Boolean,
      default: false,
    },
    showFold: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="less" scoped>
.info-header {
  border-bottom: 1px solid #f5f5f5;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  .icon {
    margin-right: 8px;
  }
  .title {
    font-size: 14px;
    color: #1890ff;
    font-weight: bold;
  }
  .fold {
    margin-left: auto;
    cursor: pointer;
    color: #999;
  }
}
</style>